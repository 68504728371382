<template>
	<div>
		<template>
			<v-tabs
				v-model="tab"
				fixed-tabs
				background-color="indigo"
				dark
			>
<!--				<v-tab>
					Tìm kiếm thông tin kiện hàng TO
				</v-tab>-->
				<v-tab>
					Danh sách kiện hàng trong kho
				</v-tab>
				<v-tab>
					Các kiện hàng đã gộp
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
<!--				<v-tab-item>
					<v-card>
						<v-card-text>
							<v-row>
								<v-col cols="12" sm="12" md="12" lg="12" xl="12">
									<v-text-field
										v-model="Tracking"
										label="Tìm kiếm"
										append-icon="search"
										single-line
										solo
										hide-details
										@change="doSearchTracking"
									/>
								</v-col>
							</v-row>
							<br />
							<v-data-table
								:headers="headersTO"
								:items="itemsTO"
								:loading="loaderTO"
								:expanded.sync="expandedTO"
								:single-expand="false"
								item-key="_id"
								show-expand
								class="elevation-1"
							>
								<template v-slot:[`item.Print`]="{ item }">
									<v-btn
										color="success"
										@click="doPrintTO(item)"
									>
										In
									</v-btn>
								</template>
								<template v-slot:[`item.Import`]="{ item }">
									<template v-if="item.Imported === false && item.Items.filter(f=>f['Tracking'] === Tracking && f['Imported'] === false).length > 0">
										<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												color="blue"
												dark
												v-bind="attrs"
												v-on="on"
											>
												Nhập kho
											</v-btn>
										</template>
										<v-list>
											<v-list-item>
												<v-list-item-title>
													<v-btn
														color="green"
														dark
														small
														@click="doImportOrder(item,'air')"
													>
														Air
													</v-btn>
												</v-list-item-title>
											</v-list-item>
											<v-list-item>
												<v-list-item-title>
													<v-btn
														color="blue"
														dark
														small
														@click="doImportOrder(item,'ship')"
													>
														Sea
													</v-btn>
												</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
									</template>
									<template v-else>
										<v-chip
											color="green"
											dark
										>
											<template v-if="item.Imported === true">
												Đơn hàng đã nhập kho
											</template>
											<template v-else>
												Tracking đã nhập kho
											</template>
										</v-chip>
									</template>
								</template>
								<template v-slot:expanded-item="{ headers, item }">
									<td/>
									<td>
										<template v-if="item['ProductName']">
											<v-list-item
												three-line
											>
												<v-list-item-content>
													<v-list-item-title>
														Khách hàng: {{ item.Customer.Username }}
													</v-list-item-title>
													<v-list-item-subtitle>
														{{ item['ProductName'] }}
													</v-list-item-subtitle>
												</v-list-item-content>
											</v-list-item>
										</template>
										<template v-else>
											<v-list-item
												three-line
											>
												<v-list-item-content>
													<v-list-item-title>
														Khách hàng: {{ item.Customer.Username }}
													</v-list-item-title>
													<v-list-item-subtitle>
														<p>Danh sách hàng hóa</p>
														<template
															v-for="itemData in item.Items"
														>
														<p
															:key="itemData['_id']"
															v-if="itemData['Tracking'] === Tracking && itemData['Imported'] === false"
														>
															{{ itemData['Name'] }} x {{ itemData['Quantity'] }}
														</p>
														</template>
													</v-list-item-subtitle>
												</v-list-item-content>
											</v-list-item>
										</template>
									</td>
								</template>
							</v-data-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
				-->
				<v-tab-item>
					<v-card>
						<v-card-title>
							<v-row>
								<v-col cols="12">
									<v-row>
										<v-col cols="auto" v-if="StaffLevel === 'admin' || StaffLevel === 'manager'">
											<v-autocomplete
												v-model="Filter['sale']"
												:items="ListSale"
												item-value="_id"
												item-text="Username"
												label="Sale"
											/>
										</v-col>
										<v-col cols="auto">
											<v-autocomplete
												v-model="Filter['customer']"
												:items="ListCustomer"
												item-value="_id"
												item-text="Username"
												label="Customer"
											/>
										</v-col>
										<v-col cols="12">
											<v-btn
												class="mx-2"
												dark
												color="green"
												@click="doDownloadList()"
											>
												Lọc
											</v-btn>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="12" />
								<v-col cols="auto">
									<v-btn
										dark
										color="green"
										@click="dialogAdd = true"
										:disabled="selected.length === 0"
									>
										Gộp
									</v-btn>
								</v-col>
							</v-row>
							<v-spacer />
							<v-text-field
								v-model="searchList"
								label="Tìm kiếm"
							/>
						</v-card-title>
						<v-card-text>
							<v-data-table
								:search="searchList"
								:headers="headerList"
								:items="items"
								:items-per-page="50"
								class="elevation-1"
							>
								<template v-slot:[`item.Shipping`]="{ item }">
									<template v-if="item.Shipping === 'air'">
										<v-icon>mdi-airplane</v-icon>
									</template>
									<template v-else>
										<v-icon>mdi-ferry</v-icon>
									</template>
								</template>
								<template v-slot:[`item.tracking`]="{ item }">
									<template v-if="item.Items.length > 0">
										{{item.Items.filter(item=>item.Imported).map(item=>item.Tracking).join(',')}}
									</template>
									<template v-else>
										{{item.tracking}}
									</template>
								</template>
								<template v-slot:[`item.select`]="{ item }">
									<template v-if="item.Imported === false">
										<v-chip
											color="red"
											dark
										>
											Kiện hàng thuộc đơn hàng chưa đầy đủ
										</v-chip>
									</template>
									<template v-if="itemsMarked.includes(item['_id'])">
										<v-chip
											color="green"
											dark
										>
											Kiện hàng đã gộp
										</v-chip>
									</template>
									<template v-if="(CurrentCustomer === null || CurrentCustomer === item['Customer']['_id']) && !itemsMarked.includes(item['_id']) && item['Address'] !== null && (CurrentAddress === null || CurrentAddress === item['Address']) && item.Imported === true">
										<v-checkbox
											:value="selected.includes(item['_id'])"
											@change="doUpdateListItem(item, !selected.includes(item['_id']))"
										/>
									</template>
									<template v-if="CurrentCustomer !== item['Customer']['_id'] && CurrentCustomer !== null">
										<v-chip
											color="red"
											dark
										>
											Không cùng khách hàng
										</v-chip>
									</template>
									<template v-if="CurrentAddress !== item['Address'] && CurrentAddress !== null && !itemsMarked.includes(item['_id'])">
										<v-chip
											color="red"
											dark
										>
											Không cùng địa chỉ
										</v-chip>
									</template>
									<template v-if="item['Address'] === null">
										<v-chip
											color="orange"
											dark
										>
											Đơn hàng chưa cập nhật địa chỉ nhận hàng
										</v-chip>
									</template>
								</template>
							</v-data-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card>
						<v-card-title>
							<v-spacer />
							<v-text-field
								v-model="searchMark"
								label="Tìm kiếm"
							/>
						</v-card-title>
						<v-card-text>
							<v-data-table
								:search="searchMark"
								:headers="headerListMark"
								:items="ListMarkData"
								:expanded.sync="expanded"
								:single-expand="false"
								item-key="_id"
								show-expand
								:items-per-page="50"
								class="elevation-1"
							>
								<template v-slot:[`item.Shipment`]="{ item }">
									<template v-if="item.Shipment">
										<v-btn
											color="green"
											dark
											:to="`shipment/${item.Shipment._id}`"
										>
											{{item.Shipment.Code}}
										</v-btn>
									</template>
									<template v-else>
										<v-btn
											color="blue"
											dark
											@click="doToggleShipment(item)"
										>
											Thêm chuyến
										</v-btn>
									</template>
								</template>
								<template v-slot:expanded-item="{ headers, item }">
									<td></td>
									<td>
										<v-list-item
											three-line
											v-for="itemData in item.Items"
											:key="itemData['_id']"
										>
											<v-list-item-content>
												<v-list-item-title>
													Đơn hàng:
													{{itemData['Code']}}
												</v-list-item-title>
												<v-list-item-subtitle>
													Tracking:
													<template v-if="Array.isArray(itemData['Tracking'])">
														{{itemData['Tracking'].filter(f=>f.Imported).map(m=>m.Tracking).join(',')}}
													</template>
													<template v-else>
														{{itemData['Tracking']}}
													</template>
												</v-list-item-subtitle>
												<v-list-item-subtitle
													v-if="!item['Shipment']"
												>
													<v-btn
														class="mx-2"
														dark
														x-small
														color="green"
														@click="doRemoveOrder(itemData['_id'],item)"
													>
														Gỡ đơn hàng
													</v-btn>
												</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</td>
								</template>
							</v-data-table>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</template>
		<v-dialog
			max-width="600px"
			v-model="dialogAdd"
			persistent
		>
			<v-card>
				<v-card-title>
					{{ markData['_id'] ? 'Cập nhật':'Gộp kiện'}}
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col
							cols="auto"
						>
							<v-select
								v-model="method"
								class="mx-2"
								:items="ListMark"
								item-value="_id"
								item-text="Name"
								label="Lựa chọn kiện có sẵn hoặc tạo mới"
								@change="doUpdateDataItem()"
							/>
						</v-col>
						<v-col
							cols="auto"
						>
							<v-text-field
								v-model="markData['Name']"
								class="mx-2"
								label="Tên kiện gộp"
								v-if="method === null"
							/>
							<v-text-field
								v-model="markData['Name']"
								class="mx-2"
								label="Tên kiện gộp"
								v-else
								disabled
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<v-simple-table>
								<template v-slot:default>
									<thead>
									<tr>
										<th class="text-left">
											Tracking
										</th>
									</tr>
									</thead>
									<tbody>
									<tr
										v-for="item in selected"
										:key="item"
									>
										<td>{{ dataByID(item)['Items'].length > 0 ? dataByID(item)['Items'].filter(f=>f.Imported).map(f=>f.Tracking).join(','):dataByID(item)['tracking']  }}</td>
									</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn class="mx-2" dark @click="dialogAdd = false">
						Đóng
					</v-btn>
					<v-btn class="mx-2" dark @click="doMerge" color="green">
						Gộp kiện
					</v-btn>
				</v-card-actions>
				<loading :status="loaderAdd" />
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="dialogAddShipment"
			max-width="600px"
		>
			<v-card flat>
				<v-card-title>Thêm kiện vào chuyến hàng</v-card-title>
				<v-card-text>
					<v-row>
						<v-col
							cols="auto"
						>
							<v-select
								v-model="shipmentID"
								:items="ListShipments"
								item-value="_id"
								item-text="Code"
								label="Chuyến hàng"
								@change="doGetShipmentPackage"
							/>
						</v-col>
						<v-col
							cols="auto"
						>
							<v-text-field
								:value="latestPackage || 'Không có dữ liệu'"
								label="Mã lô hàng mới nhất"
								disabled
							/>
						</v-col>
						<v-col
							cols="auto"
							>
							<v-text-field
								v-model="packageData.Code"
								label="Mã lô hàng mong muốn"
								:hint="AutoCode"
								persistent-hint
								:disabled="!shipmentID"
							/>
						</v-col>
						<v-col
							cols="auto"
						>
							<v-select
								v-model="packageData.Type"
								:items="PackageType"
								item-value="code"
								item-text="text"
								:rules="[required('Loại')]"
								label="Loại"
							/>
						</v-col>
						<v-col
							cols="auto"
						>
							<v-text-field
								v-model="packageData.GW"
								label="Khối lượng"
							/>
						</v-col>
						<v-col
							cols="auto"
						>
							<v-text-field
								v-model="packageData.D"
								label="Dài"
							/>
						</v-col>
						<v-col
							cols="auto"
						>
							<v-text-field
								v-model="packageData.R"
								label="Rộng"
							/>
						</v-col>
						<v-col
							cols="auto"
						>
							<v-text-field
								v-model="packageData.C"
								label="Cao"
							/>
						</v-col>
						<v-col
							cols="auto"
						>
							<v-text-field
								:value="NW"
								label="NW"
								hint="DxRxC/6000"
								persistent-hint
								disabled
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="blue"
						dark
						:disabled="!isValid"
						@click="doImport2Shipment"
					>
						Thêm
					</v-btn>
					<v-spacer />
					<v-btn
						dark
						@click="dialogAddShipment = false"
					>
						Đóng
					</v-btn>
				</v-card-actions>
			</v-card>
			<loading :status="loaderShipment" />
		</v-dialog>
	</div>
</template>

<script>
import validations from '@/plugins/helper/validations'
import {Sales} from '@/plugins/query/global.js';
import { Status } from '@/plugins/helper/dstatus';
import {doList, doListMark, doCreateList, doUpdateList} from '@/plugins/query/tomorder.js';
import loading from '@/components/base/Loading.vue'
import {mapGetters} from "vuex";
import {doShipmentPackage, doShipments, doImportShipment, doSearch, doImport, getPDF} from "../plugins/query/tomorder";
import printJS from "print-js";
export default {
	name: "tom-order",
	components: {
		loading,
	},
	data(){
		return {
			Code: '',
			Tracking:null,
			Shipping:'air',
			Filter:{
				sale: null,
				customer: null,
			},
			expanded: [],
			expandedTO: [],
			notice: new Status(this.$swal),
			method: null,
			searchList: null,
			searchMark: null,
			tab:0,
			selected: [],
			markData: {},
			items: [],
			itemsmark: [],
			itemsTO: [],
			loaderTO: false,
			loaderList: false,
			loaderMark: false,
			loader: false,
			loaderAdd: false,
			loaderSale: false,
			loaderCustomer: false,
			dialogAdd: false,
			dialogAddShipment: false,
			loaderShipment: false,
			itemsMarked: [],
			shipmentID: null,
			latestPackage: null,
			headerList:[
				{ text: 'Lựa chọn', value: 'select', align: 'center',width: "10%",class:"text-center grey lighten-2 black--text" },
				{ text: 'Tracking', value: 'tracking', align: 'center',width: "50%",class:"text-center grey lighten-2 black--text" },
				{ text: 'Vận chuyển', value: 'Shipping', align: 'center',width: "10%",class:"text-center grey lighten-2 black--text" },
				{ text: 'Khách hàng', value: 'Customer.Username', align: 'center',width: "30%",class:"text-center grey lighten-2 black--text" },
			],
			headerListMark:[
				{ text: 'Tên kiện hàng', value: 'Name', align: 'center',width: "90%",class:"text-center grey lighten-2 black--text" },
				{ text: 'Chuyến', value: 'Shipment', align: 'center',width: "10%",class:"text-center grey lighten-2 black--text" },
			],
			Shipments: [],
			packageData:{},
			PackageType: [
				{code:'carton',text:'Loose carton'},
				{code:'pallet',text:'Pallet'},
				{code:'crate',text:'Wooden crate'},
				{code:'bag',text:'Bag'},
			],
			headersTO:[
				{ text: 'Đơn hàng TO', value: 'Code', align: 'center',width: "80%",class:"text-center grey lighten-2 black--text" },
				{ text: 'Nhập', value: 'Import', align: 'center',width: "10%",class:"text-center grey lighten-2 black--text" },
				{ text: 'In', value: 'Print', align: 'center',width: "10%",class:"text-center grey lighten-2 black--text" },
			],
		}
	},
	computed:{
		...mapGetters(['authStatus','isAuthenticated','loginInfo','packageFilter']),
		isValid(){
			const {Code, Type} = this.packageData;
			return Code && Type;
		},
		NW(){
			let {D,R,C} = this.packageData;
			D = parseInt(D); R = parseInt(R); C = parseInt(C);
			D = isNaN(D) ? 0: D; R = isNaN(R) ? 0:R; C = isNaN(C) ? 0:C;
			return (D*R*C/6000).toFixed(2);
		},
		Shipment(){
			if(this.Shipments.length === 0) return {};
			if(!this.shipmentID) return {};
			const index = this.Shipments.findIndex(item=>item._id === this.shipmentID);
			if(index > -1){
				return this.Shipments[index];
			}
			return {};
		},
		ListShipments(){
			if(this.Shipments.length === 0) return [{_id:null,Code:'NO SHIPMENT'}];
			if(!this.packageData.Shipping) return [{_id:null,Code:'NO Package Shipping'}];
			return this.Shipments.filter(f=>f['Type'] === this.packageData.Shipping);
		},
		AutoCode(){
			const {Shipment, packageData} = this;
			if(Shipment.Code){
				const Code = (packageData.Code) ? packageData.Code : '';
				return `${Shipment.NameAuto}-${Code}-1/1`;
			}
			return 'Chưa có dữ liệu';
		},
		ListSale(){
			let Sale = [{_id:null,Username:'Tất cả'}];
			this.items.map(item=>{
				const SaleData = {_id:item.Customer.Sale._id, Username: item.Customer.Sale.Username};
				if(!Sale.includes(SaleData)) Sale.push(SaleData);
			});
			return Sale;
		},
		ListCustomer(){
			let List = [{_id:null,Username:'Tất cả'}];
			this.items.map(item=>{
				const Customer = {_id:item.Customer._id, Username: item.Customer.Username};
				if(!List.includes(Customer)) List.push(Customer);
			});
			return List;
		},
		StaffLevel(){
			return this.loginInfo._perm;
		},
		ListMark(){
			return this.itemsmark.filter(f=>!f.Shipment).concat([{_id:null,Name:"Tạo mới"}]);
		},
		CurrentCustomer(){
			return this.dataByID(this.selected[0]) ? this.dataByID(this.selected[0])['Customer']['_id']:null;
		},
		CurrentAddress(){
			return this.dataByID(this.selected[0]) ? this.dataByID(this.selected[0])['Address']:null;
		},
		ListMarkData(){
			return this.itemsmark.map(item=>({
					...item,
					ItemsData: item.Items.map(_item => ({...this.dataByID(_item)})),
				}));
		},
		Query(){
			const qs = Object.keys(this.Filter)
				.map(key => this.Filter[key] ? `${key}=${this.Filter[key]}` : undefined)
				.filter(item=>item)
				.join('&');
			return qs;
		}
	},
	methods:{
		...validations,
		doPrintTO(item){
			if(this.Tracking && item.Customer.Username){
				this.loaderTO = true;
				getPDF(this.Tracking, item.Customer.Username).then(pdfFile=>{
					const pdfUrl = URL.createObjectURL(pdfFile);
					printJS(pdfUrl);
				}).finally(()=>{
					this.loaderTO = false;
				});
			}
		},
		doImportOrder(item,ship){
			const {_id} = item;
			if(_id && (ship === 'air' || ship === 'ship')){
				this.loaderTO = true;
				doImport({Order:_id,Shipping:ship, Tracking:this.Tracking}).then(resp=>{
					const {success,error} = resp;
					if(success) {
						this.notice.success('Đã nhập kho các đơn hàng thuộc mã vận đơn: ' + this.Tracking);
						this.Tracking = null;
						this.itemsTO = [];
						this.doDownloadAll();
					}else{
						const msg = error || "Không thể nhập kho đơn hàng thuộc mã vận đơn: " + this.Tracking;
						this.notice.error(msg);
						this.Tracking = null;
						this.itemsTO = [];
					}
				}).catch(e=>{
					console.error(e);
					this.notice.error('Có lỗi khi xử lí yêu cầu !!!');
				}).finally(()=>{
					this.loaderTO = false;
				})
			}
		},
		doSearchTracking(){
			if(!this.Tracking) return;
			this.loaderTO = true;
			doSearch(this.Tracking).then(resp=> {
				const {data, error} = resp;
				if(data){
					this.itemsTO = data;
				}else{
					this.notice.error('Không thể tìm thấy dữ liệu !!!');
				}
				if(error){
					this.notice.error(error);
				}
			}).catch(e=>{
				console.error(e);
				this.notice.error('Có lỗi khi xử lí yêu cầu !!!');
			}).finally(()=>{
				this.loaderTO = false;
			})
		},
		doImport2Shipment(){
			const {_id, Code, D, R, C, GW, Type} = this.packageData;
			const {shipmentID} = this;
			if(!Code || !Type){
				this.notice.error('Vui lòng nhập mã kiện hàng và chọn loại');
				return;
			}
			const request = {
				Shipment: shipmentID,
				Code:this.AutoCode,
				NW:this.NW,
				GW,
				D,
				R,
				C,
				Type
			}
			this.loaderShipment = true;
			doImportShipment(_id,request).then(resp=>{
				const {error,success} = resp;
				if(success){
					this.shipmentID = null;
					this.dialogAddShipment = false;
					this.packageData = {};
					this.doDownloadListMark();
					this.notice.success('Thông tin kiện hàng đã nhập vào chuyến thành công !!!');
				}
				if(error){
					const msg = error || 'Không thể nhập thông tin kiện hàng vào chuyến !!!';
					this.notice.error(msg);
				}
			})
			.finally(()=>{
				this.loaderShipment = false;
			})
		},
		doGetShipmentPackage(){
			const {shipmentID} = this;
			this.loaderShipment = true;
			doShipmentPackage(shipmentID).then(resp=>{
				const {data} = resp;
				this.latestPackage = data.Code;
			}).finally(()=>{
				this.loaderShipment = false;
			});
		},
		doToggleShipment(item){
			this.shipmentID = null;
			this.dialogAddShipment = true;
			const data = JSON.parse(JSON.stringify(item));
			this.packageData = data;
		},
		doUpdateDataItem(){
			this.markData = {
				Name: this.dataMardByID(this.method)['Name']
			}
		},
		doRemoveOrder(id, item){

			let Items = item.Items;
			this.loaderMark = true;
			doUpdateList(item._id, Items.filter(v=>v._id !== id)).then(resp=>{
				const {success, error} = resp;
				if(success){
					this.notice.success('Đã cập nhật kiện hàng thành công !!!');
					this.doDownloadAll();
				}
				if(error){
					const msg = error || "Không thể cập nhật kiện hàng !!!";
					this.notice.error(msg);
				}
			}).finally(()=>{
				this.loaderMark = false;
			})
		},
		doMerge(){
			const {selected, method, markData} = this;
			if(selected.length > 0){
				const request = {
					merge: method ? method:undefined,
					Name: markData['Name'],
					items: selected
				}
				this.loaderAdd = true;
				doCreateList(request).then(resp=>{
					const {success,error} = resp;
					if(success){
						this.notice.success('Đã tạo kiện hàng gộp thành công !!!');
						this.doDownloadAll();
					}
					if(error){
						const msg = error || "Không thể tạo kiện hàng gộp !!!";
						this.notice.error(msg);
					}
				}).finally(()=>{
					this.loaderAdd = false;
					this.dialogAdd = false;
					this.markData = {};
					this.selected = [];
				})
			}else{
				this.notice.error('Phải có danh sách hàng trong kiện hàng gộp !!!');
			}
		},
		dataMardByID(id){
			if(!id) return null;
			const result = this.itemsmark.filter(item=>item['_id'] === id);
			return result[0]
		},
		dataByID(id){
			if(!id) return null;
			const result = this.items.filter(item=>item['_id'] === id);
			return result[0]
		},
		doUpdateListItem(item, status){
			if(this.CurrentCustomer !== null && this.CurrentCustomer !== item['Customer']['_id']) return;
			if(!this.selected.includes(item['_id']) && status) this.selected.push(item['_id']);
			if(!status){
				delete this.selected[item['_id']];
			}
		},
		doDownloadList(){
			this.loaderList = true;
			return doList(this.Query).then(resp=>{
				const {data} = resp;
				this.items = data;
			}).finally(()=>{
				this.loaderList = false;
			});
		},
		doDownloadListMark(){
			this.loaderMark = true;
			return doListMark().then(resp=>{
				const {data} = resp;
				this.itemsmark = data;
				this.itemsMarked = [];
				for(let i=0;i<data.length;i++){
					const item = data[i];
					this.itemsMarked = this.itemsMarked.concat(item['Items'].map(m=>m._id));
				}
			}).finally(()=>{
				this.loaderMark = false;
			});
		},
		doDownloadListShipments(){
			this.loaderShipment = true;
			return doShipments().then(resp=>{
				const {data} = resp;
				this.Shipments = data;
				this.shipmentID = null;
			}).finally(()=>{
				this.loaderShipment = false;
			});
		},
		doDownloadAll(){
			this.loader = true;
			let APIs = [this.doDownloadList(),this.doDownloadListMark(), this.doDownloadListShipments()];
			return Promise.all(APIs).finally(()=>{
				this.loader = false;
			});
		}
	},
	mounted(){
		this.doDownloadAll();
		const {StaffLevel} = this;
		if(StaffLevel === 'admin' || StaffLevel === 'manager'){
			this.headerList.push({ text: 'Sale', value: 'Customer.Sale.Username', align: 'center',width: "40%",class:"text-center grey lighten-2 black--text" });
		}
	}
}
</script>

<style scoped>

</style>